import { ENTITY_TYPES } from '@/config/enums';
import {
  Project,
  Company,
  Tender,
  Contact,
  Activity,
  Note,
  File,
  Client,
  User,
  ReleaseNote,
  ApiClient,
  Webhook,
  WebhooksQueue,
  EmailLog,
  SavedSearchNotification,
  UserTransferTask,
  EmailLogEvent,
  Component,
  Module,
  DeadMessage,
  DeadMessageSubscription,
  DefaultFieldset,
  VortalCompany,
  VortalContact,
  VortalProject,
  VortalLog,
  VortalNotificationEmail,
  MyranUserProfile,
  MyranSuspendedUser,
  MyranClientVersion,
  MyranUserVersion,
  MyranSubscriptionVersion,
  UsageStatsClient,
  UsageStatsUser,
  UsageStatsClientWidget,
  UsageStatsUserWidgets,
  CustomFieldset,
  CustomFieldMetric,
  CustomFieldSelectionMetric,
  PMClient,
  PMClientVersion,
  PMCompany,
  PMContact,
  PMProject,
  PMDocument,
  PMEmployee,
  PMActivity,
  PMCustomField,
  PMCustomFieldSelection,
  PMCustomFieldValue,
  PMCustomFieldEmployee,
  ErrorMessage,
  Subscription,
  License,
} from '@/entities';

const defaultTypeId = ENTITY_TYPES.PROJECT;

export class EntityFactory {
  static getClass(typeId) {
    let res;

    switch (typeId) {
      case ENTITY_TYPES.PROJECT:
        res = Project;
        break;
      case ENTITY_TYPES.COMPANY:
        res = Company;
        break;
      case ENTITY_TYPES.TENDER:
        res = Tender;
        break;
      case ENTITY_TYPES.CONTACT:
        res = Contact;
        break;
      case ENTITY_TYPES.ACTIVITY:
        res = Activity;
        break;
      case ENTITY_TYPES.NOTE:
        res = Note;
        break;
      case ENTITY_TYPES.FILE:
        res = File;
        break;
      case ENTITY_TYPES.CLIENT:
        res = Client;
        break;
      case ENTITY_TYPES.USER:
        res = User;
        break;
      case ENTITY_TYPES.RELEASE_NOTE:
        res = ReleaseNote;
        break;
      case ENTITY_TYPES.API_CLIENT:
        res = ApiClient;
        break;
      case ENTITY_TYPES.WEBHOOK:
        res = Webhook;
        break;
      case ENTITY_TYPES.WEBHOOK_QUEUE:
        res = WebhooksQueue;
        break;
      case ENTITY_TYPES.EMAIL_LOG:
        res = EmailLog;
        break;
      case ENTITY_TYPES.SAVED_SEARCH_NOTIFICATION:
        res = SavedSearchNotification;
        break;
      case ENTITY_TYPES.USER_TRANSFER_TASK:
        res = UserTransferTask;
        break;
      case ENTITY_TYPES.EMAIL_LOG_EVENT:
        res = EmailLogEvent;
        break;
      case ENTITY_TYPES.COMPONENT:
        res = Component;
        break;
      case ENTITY_TYPES.MODULE:
        res = Module;
        break;
      case ENTITY_TYPES.DEAD_MESSAGE:
        res = DeadMessage;
        break;
      case ENTITY_TYPES.DEAD_MESSAGE_SUBSCRIPTION:
        res = DeadMessageSubscription;
        break;
      case ENTITY_TYPES.DEFAULT_FIELDSET:
        res = DefaultFieldset;
        break;
      case ENTITY_TYPES.VORTAL_COMPANY:
        res = VortalCompany;
        break;
      case ENTITY_TYPES.VORTAL_CONTACT:
        res = VortalContact;
        break;
      case ENTITY_TYPES.VORTAL_PROJECT:
        res = VortalProject;
        break;
      case ENTITY_TYPES.VORTAL_NOTIFICATION_EMAIL:
        res = VortalNotificationEmail;
        break;
      case ENTITY_TYPES.VORTAL_LOG:
        res = VortalLog;
        break;
      case ENTITY_TYPES.MYRAN_CLIENT_VERSION:
        res = MyranClientVersion;
        break;
      case ENTITY_TYPES.MYRAN_SUBSCRIPTION_VERSION:
        res = MyranSubscriptionVersion;
        break;
      case ENTITY_TYPES.MYRAN_SUSPENDED_USER:
        res = MyranSuspendedUser;
        break;
      case ENTITY_TYPES.MYRAN_USER_PROFILE:
        res = MyranUserProfile;
        break;
      case ENTITY_TYPES.MYRAN_USER_VERSION:
        res = MyranUserVersion;
        break;
      case ENTITY_TYPES.USAGE_STATS_CLIENT:
        res = UsageStatsClient;
        break;
      case ENTITY_TYPES.USAGE_STATS_USER:
        res = UsageStatsUser;
        break;
      case ENTITY_TYPES.USAGE_STATS_USER_WIDGET:
        res = UsageStatsUserWidgets;
        break;
      case ENTITY_TYPES.USAGE_STATS_CLIENT_WIDGET:
        res = UsageStatsClientWidget;
        break;
      case ENTITY_TYPES.CUSTOM_FIELDSET:
        res = CustomFieldset;
        break;
      case ENTITY_TYPES.CUSTOM_FIELD_METRIC:
        res = CustomFieldMetric;
        break;
      case ENTITY_TYPES.CUSTOM_FIELD_SELECTION_METRIC:
        res = CustomFieldSelectionMetric;
        break;
      case ENTITY_TYPES.PM_CLIENT:
        res = PMClient;
        break;
      case ENTITY_TYPES.PM_CLIENT_VERSION:
        res = PMClientVersion;
        break;
      case ENTITY_TYPES.PM_COMPANY:
        res = PMCompany;
        break;
      case ENTITY_TYPES.PM_PROJECT:
        res = PMProject;
        break;
      case ENTITY_TYPES.PM_CONTACT:
        res = PMContact;
        break;
      case ENTITY_TYPES.PM_ACTIVITY:
        res = PMActivity;
        break;
      case ENTITY_TYPES.PM_DOCUMENT:
        res = PMDocument;
        break;
      case ENTITY_TYPES.PM_EMPLOYEE:
        res = PMEmployee;
        break;
      case ENTITY_TYPES.PM_CUSTOM_FIELD:
        res = PMCustomField;
        break;
      case ENTITY_TYPES.PM_CUSTOM_FIELD_VALUE:
        res = PMCustomFieldValue;
        break;
      case ENTITY_TYPES.PM_CUSTOM_FIELD_SELECTION:
        res = PMCustomFieldSelection;
        break;
      case ENTITY_TYPES.PM_CUSTOM_FIELD_EMPLOYEE:
        res = PMCustomFieldEmployee;
        break;
      case ENTITY_TYPES.ERROR_MESSAGE:
        res = ErrorMessage;
        break;
      case ENTITY_TYPES.SUBSCRIPTION:
        res = Subscription;
        break;
      case ENTITY_TYPES.LICENSE:
        res = License;
        break;
      default:
        res = Project;
    }

    return res;
  }
  static create(...args) {
    const typeId = args[0] || defaultTypeId;
    const EntityClass = this.getClass(typeId);
    return new EntityClass(...args);
  }
}
