/*
  DefaultFieldsetsList class
 */

import Vue from 'vue';
import EntityList from '@/shared/classes/list/EntityList';
import DefaultFieldsetsTableView from '@/entities/default-fieldset/views/DefaultFieldsetsTableView';
import { SEARCH_FILTER_TYPES } from '@/config/enums';
import { getDefaultFieldsetsListsConfig } from '@/entities/default-fieldset/config';
import { requiredFields } from '@/entities/default-fieldset/fields';
import { DefaultFieldset } from '@/entities';

export class DefaultFieldsetsList extends EntityList {
  static entityClass = DefaultFieldset;

  constructor(listTypeId) {
    super(listTypeId, getDefaultFieldsetsListsConfig);

    this.tableView = DefaultFieldsetsTableView;

    this.selfClass = DefaultFieldsetsList;
    this.getRequiredFields = requiredFields;
  }

  getListFiltersSetting(payload = {}) {
    return [
      {
        key: 'country_id',
        dataCy: 'country',
        name: 'Country',
        filterType: SEARCH_FILTER_TYPES.SMO_CHECKBOXES_LIST,
        filterAttrs: {
          items: Vue.prototype.$lDict('global.countries'),
        },
      },
      {
        key: 'list_type_id',
        dataCy: 'list-type',
        name: 'List type',
        filterType: SEARCH_FILTER_TYPES.SMO_CHECKBOXES_LIST,
        filterAttrs: {
          items: payload.listTypes || [],
        },
      },
    ];
  }
}
