// Entity classes
import { Project } from './project/Project';
import { UserProject } from './project/UserProject';
import { Company } from './company/Company';
import { UserCompany } from './company/UserCompany';
import { Tender } from './tender/Tender';
import { UserTender } from '@/entities/tender/UserTender';
import { Contact } from './contact/Contact';
import { UserContact } from './contact/UserContact';
import { Activity } from './activity/Activity';
import { Client } from './client/Client';
import { Note } from './note/Note';
import { File } from './file/File';
import { User } from './user/User';
import { ReleaseNote } from './release-note/ReleaseNote';
import { ApiClient } from './api-client/ApiClient';
import { Webhook } from './webhook/Webhook';
import { UserWebhook } from './webhook/UserWebhook';
import { WebhooksQueue } from './webhooks-queue/WebhooksQueue';
import { EmailLog } from '@/entities/email-log/EmailLog';
import { SavedSearchNotification } from '@/entities/saved-search-notif/SavedSearchNotification';
import { UserTransferTask } from '@/entities/user-transfer-task/UserTransferTask';
import { EmailLogEvent } from '@/entities/email-log-event/EmailLogEvent';
import { Component } from '@/entities/component/Component';
import { Module } from '@/entities/module/Module';
import { DeadMessage } from '@/entities/dead-message/DeadMessage';
import { DeadMessageSubscription } from '@/entities/dead-message-subscription/DeadMessageSubscription';
import { DefaultFieldset } from '@/entities/default-fieldset/DefaultFieldset';
import { VortalCompany } from '@/entities/vortal-company/VortalCompany';
import { VortalContact } from '@/entities/vortal-contact/VortalContact';
import { VortalProject } from '@/entities/vortal-project/VortalProject';
import { VortalLog } from '@/entities/vortal-log/VortalLog';
import { VortalNotificationEmail } from '@/entities/vortal-notification-email/VortalNotificationEmail';
import { MyranClientVersion } from '@/entities/myran-client-version/MyranClientVersion';
import { MyranSuspendedUser } from '@/entities/myran-suspended-user/MyranSuspendedUser';
import { MyranUserProfile } from '@/entities/myran-user-profile/MyranUserProfile';
import { MyranUserVersion } from '@/entities/myran-user-version/MyranUserVersion';
import { MyranSubscriptionVersion } from '@/entities/myran-subscription-version/MyranSubscriptionVersion';
import { UsageStatsUser } from '@/entities/usage-stats-user/UsageStatsUser';
import { UsageStatsClient } from '@/entities/usage-stats-client/UsageStatsClient';
import { CustomFieldset } from '@/entities/custom-fieldset/CustomFieldset';
import { CustomFieldMetric } from '@/entities/custom-field-metric/CustomFieldMetric';
import { CustomFieldSelectionMetric } from '@/entities/custom-field-selection-metric/CustomFieldSelectionMetric';
import { PMClient } from '@/entities/parabyg-migration/pm-client/PMClient';
import { PMClientVersion } from '@/entities/parabyg-migration/pm-client-version/PMClientVersion';
import { PMCompany } from '@/entities/parabyg-migration/pm-company/PMCompany';
import { PMProject } from '@/entities/parabyg-migration/pm-project/PMProject';
import { PMActivity } from '@/entities/parabyg-migration/pm-activity/PMActivity';
import { PMContact } from '@/entities/parabyg-migration/pm-contact/PMContact';
import { PMDocument } from '@/entities/parabyg-migration/pm-document/PMDocument';
import { PMEmployee } from '@/entities/parabyg-migration/pm-employee/PMEmployee';
import { PMCustomField } from '@/entities/parabyg-migration/pm-custom-field/PMCustomField';
import { PMCustomFieldSelection } from '@/entities/parabyg-migration/pm-custom-field-selection/PMCustomFieldSelection';
import { PMCustomFieldValue } from '@/entities/parabyg-migration/pm-custom-field-value/PMCustomFieldValue';
import { PMCustomFieldEmployee } from '@/entities/parabyg-migration/pm-custom-field-employee/PMCustomFieldEmployee';
import { ErrorMessage } from '@/entities/error-messages/ErrorMessage';
import { Subscription } from '@/entities/subscriptions/Subscription';
import { ClientUser } from '@/entities/client-user/ClientUser';
import { UserProfile } from '@/entities/user-profile/UserProfile';
import { License } from '@/entities/licenses/License';
import { DefaultDashboardWidgets } from '@/entities/default-dashboard-widgets/DefaultDashboardWidgets';
import { CountryModules } from '@/entities/country-modules/CountryModules';
import { DefaultExportFieldset } from '@/entities/default-export-fieldset/DefaultExportFieldset';
import { UsageStatsClientWidget } from '@/entities/usage-stats-client-widgets/UsageStatsClientWidget';
import { UsageStatsUserWidgets } from '@/entities/usage-stats-user-widgets/UsageStatsUserWidgets';

// Entity list classes
import { ProjectsList } from './project/ProjectsList';
import { UserProjectsList } from '@/entities/project/UserProjectsList';
import { TendersList } from './tender/TendersList';
import { UserTendersList } from './tender/UserTendersList';
import { CompaniesList } from './company/CompaniesList';
import { UserCompaniesList } from './company/UserCompaniesList';
import { ContactsList } from './contact/ContactsList';
import { UserContactsList } from './contact/UserContactsList';
import { ActivitiesList } from './activity/ActivitiesList';
import { NotesList } from './note/NotesList';
import { FilesList } from './file/FilesList';
import { ClientsList } from './client/ClientsList';
import { UsersList } from './user/UsersList';
import { ReleaseNotesList } from './release-note/ReleaseNotesList';
import { ApiClientsList } from './api-client/ApiClientsList';
import { WebhooksList } from './webhook/WebhooksList';
import { UserWebhooksList } from './webhook/UserWebhooksList';
import { WebhooksQueueList } from './webhooks-queue/WebhooksQueueList';
import { EmailLogsList } from '@/entities/email-log/EmailLogsList';
import { SavedSearchNotificationsList } from '@/entities/saved-search-notif/SavedSearchNotificationsList';
import { UserTransferTasksList } from '@/entities/user-transfer-task/UserTransferTasksList';
import { EmailLogEventsList } from '@/entities/email-log-event/EmailLogEventsList';
import { ComponentsList } from '@/entities/component/ComponentsList';
import { ModulesList } from '@/entities/module/ModulesList';
import { DeadMessageSubscriptionsList } from '@/entities/dead-message-subscription/DeadMessageSubscriptionsList';
import { DeadMessagesList } from '@/entities/dead-message/DeadMessagesList';
import { DefaultFieldsetsList } from '@/entities/default-fieldset/DefaultFieldsetsList';
import { VortalCompaniesList } from '@/entities/vortal-company/VortalCompaniesList';
import { VortalContactsList } from '@/entities/vortal-contact/VortalContactsList';
import { VortalNotificationEmailsList } from '@/entities/vortal-notification-email/VortalNotificationEmailsList';
import { VortalLogsList } from '@/entities/vortal-log/VortalLogsList';
import { VortalProjectsList } from '@/entities/vortal-project/VortalProjectsList';
import { MyranClientVersionsList } from '@/entities/myran-client-version/MyranClientVersionsList';
import { MyranSubscriptionVersionsList } from '@/entities/myran-subscription-version/MyranSubscriptionVersionsList';
import { MyranSuspendedUsersList } from '@/entities/myran-suspended-user/MyranSuspendedUsersList';
import { MyranUserProfilesList } from '@/entities/myran-user-profile/MyranUserProfilesList';
import { MyranUserVersionsList } from '@/entities/myran-user-version/MyranUserVersionsList';
import { UsageStatsClientsList } from '@/entities/usage-stats-client/UsageStatsClientsList';
import { UsageStatsUsersList } from '@/entities/usage-stats-user/UsageStatsUsersList';
import { CustomFieldsetList } from '@/entities/custom-fieldset/CustomFieldsetList';
import { CustomFieldMetricsList } from '@/entities/custom-field-metric/CustomFieldMetricsList';
import { CustomFieldSelectionMetricsList } from '@/entities/custom-field-selection-metric/CustomFieldSelectionMetricsList';
import { PMClientsList } from '@/entities/parabyg-migration/pm-client/PMClientsList';
import { PMClientVersionsList } from '@/entities/parabyg-migration/pm-client-version/PMClientVersionsList';
import { PMCompaniesList } from '@/entities/parabyg-migration/pm-company/PMCompaniesList';
import { PMContactsList } from '@/entities/parabyg-migration/pm-contact/PMContactsList';
import { PMProjectsList } from '@/entities/parabyg-migration/pm-project/PMProjectsList';
import { PMActivitiesList } from '@/entities/parabyg-migration/pm-activity/PMActivitiesList';
import { PMDocumentsList } from '@/entities/parabyg-migration/pm-document/PMDocumentsList';
import { PMEmployeesList } from '@/entities/parabyg-migration/pm-employee/PMEmployeesList';
import { PMCustomFieldsList } from '@/entities/parabyg-migration/pm-custom-field/PMCustomFieldsList';
import { PMCustomFieldSelectionsList } from '@/entities/parabyg-migration/pm-custom-field-selection/PMCustomFieldSelectionsList';
import { PMCustomFieldValuesList } from '@/entities/parabyg-migration/pm-custom-field-value/PMCustomFieldValuesList';
import { PMCustomFieldEmployeesList } from '@/entities/parabyg-migration/pm-custom-field-employee/PMCustomFieldEmployeesList';
import { ErrorMessagesList } from '@/entities/error-messages/ErrorMessagesList';
import { SubscriptionsList } from '@/entities/subscriptions/SubscriptionsList';
import { ClientUsersList } from '@/entities/client-user/ClientUsersList';
import { UserProfilesList } from '@/entities/user-profile/UserProfilesList';
import { LicensesList } from '@/entities/licenses/LicensesList';
import { DefaultDashboardWidgetsList } from '@/entities/default-dashboard-widgets/DefaultDashboardWidgetsList';
import { CountryModulesList } from '@/entities/country-modules/CountryModulesList';
import { DefaultExportFieldsetsList } from '@/entities/default-export-fieldset/DefaultExportFieldsetsList';
import { UsageStatsClientWidgetsList } from '@/entities/usage-stats-client-widgets/UsageStatsClientWidgetsList';
import { UsageStatsUserWidgetsList } from "@/entities/usage-stats-user-widgets/UsageStatsUserWidgetsList";

// Entity list configs
import { getProjectsListsConfig } from '@/entities/project/config';
import { getCompaniesListsConfig } from '@/entities/company/config';
import { getContactsListsConfig } from '@/entities/contact/config';
import { getTendersListsConfig } from '@/entities/tender/config';
import { getNotesListsConfig } from '@/entities/note/config';
import { getActivitiesListsConfig } from '@/entities/activity/config';
import { getFilesListsConfig } from '@/entities/file/config';
import { getClientsListsConfig } from '@/entities/client/config';
import { getUsersListsConfig } from '@/entities/user/config';
import { getReleaseNotesListsConfig } from '@/entities/release-note/config';
import { getApiClientsListsConfig } from '@/entities/api-client/config';
import { getWebhooksListsConfig } from '@/entities/webhook/config';
import { getWebhooksQueueListsConfig } from '@/entities/webhooks-queue/config';
import { getEmailLogsListsConfig } from '@/entities/email-log/config';
import { getSavedSearchNotificationsListsConfig } from '@/entities/saved-search-notif/config';
import { getUserTransferTasksListsConfig } from '@/entities/user-transfer-task/config';
import { getComponentsListsConfig } from '@/entities/component/config';
import { getModulesListsConfig } from '@/entities/module/config';
import { getDeadMessagesListsConfig } from '@/entities/dead-message/config';
import { getDeadMessageSubscriptionsListsConfig } from '@/entities/dead-message-subscription/config';
import { getDefaultFieldsetsListsConfig } from '@/entities/default-fieldset/config';
import { getVortalCompaniesListsConfig } from '@/entities/vortal-company/config';
import { getVortalContactsListsConfig } from '@/entities/vortal-contact/config';
import { getVortalLogsListsConfig } from '@/entities/vortal-log/config';
import { getVortalNotificationEmailsListsConfig } from '@/entities/vortal-notification-email/config';
import { getVortalProjectsListsConfig } from '@/entities/vortal-project/config';
import { getMyranUserVersionsListsConfig } from '@/entities/myran-user-version/config';
import { getMyranSubscriptionVersionsListsConfig } from '@/entities/myran-subscription-version/config';
import { getMyranClientVersionsListsConfig } from '@/entities/myran-client-version/config';
import { getMyranUserProfilesListsConfig } from '@/entities/myran-user-profile/config';
import { getMyranSuspendedUsersListsConfig } from '@/entities/myran-suspended-user/config';
import { getUsageStatsUsersListsConfig } from '@/entities/usage-stats-user/config';
import { getUsageStatsClientsListsConfig } from '@/entities/usage-stats-client/config';
import { getCustomFieldsetListConfig } from '@/entities/custom-fieldset/config';
import { getCustomFieldMetricsListsConfig } from '@/entities/custom-field-metric/config';
import { getCustomFieldSelectionMetricsListsConfig } from '@/entities/custom-field-selection-metric/config';
import { getPMCompanyListsConfig } from '@/entities/parabyg-migration/pm-company/config';
import { getPMClientListsConfig } from '@/entities/parabyg-migration/pm-client/config';
import { getPMClientVersionListsConfig } from '@/entities/parabyg-migration/pm-client-version/config';
import { getPMActivityListsConfig } from '@/entities/parabyg-migration/pm-activity/config';
import { getPMContactListsConfig } from '@/entities/parabyg-migration/pm-contact/config';
import { getPMProjectsListsConfig } from '@/entities/parabyg-migration/pm-project/config';
import { getPMEmployeeListsConfig } from '@/entities/parabyg-migration/pm-employee/config';
import { getPMDocumentListsConfig } from '@/entities/parabyg-migration/pm-document/config';
import { getPMCustomFieldListsConfig } from '@/entities/parabyg-migration/pm-custom-field/config';
import { getPMCustomFieldSelectionListsConfig } from '@/entities/parabyg-migration/pm-custom-field-selection/config';
import { getPMCustomFieldValueListsConfig } from '@/entities/parabyg-migration/pm-custom-field-value/config';
import { getPMCustomFieldEmployeeListsConfig } from '@/entities/parabyg-migration/pm-custom-field-employee/config';
import { getErrorMessagesListsConfig } from '@/entities/error-messages/config';
import { getSubscriptionsListsConfig } from '@/entities/subscriptions/config';
import { getListUsersListsConfig } from '@/entities/client-user/config';
import { getUserProfilesListsConfig } from '@/entities/user-profile/config';
import { getLicenseListsConfig } from '@/entities/licenses/config';
import { getDefaultDashboardWidgetsListConfig } from '@/entities/default-dashboard-widgets/config';
import { getDefaultExportFieldsetsListsConfig } from '@/entities/default-export-fieldset/config';
import { getUsageStatsUserWidgetsListsConfig } from "@/entities/usage-stats-user-widgets/config";

const getEntityListConfigs = () => {
  const configsFuncs = [
    getProjectsListsConfig,
    getCompaniesListsConfig,
    getContactsListsConfig,
    getTendersListsConfig,
    getNotesListsConfig,
    getActivitiesListsConfig,
    getFilesListsConfig,
    getClientsListsConfig,
    getUsersListsConfig,
    getReleaseNotesListsConfig,
    getApiClientsListsConfig,
    getWebhooksListsConfig,
    getWebhooksQueueListsConfig,
    getEmailLogsListsConfig,
    getSavedSearchNotificationsListsConfig,
    getUserTransferTasksListsConfig,
    getComponentsListsConfig,
    getModulesListsConfig,
    getDeadMessagesListsConfig,
    getDeadMessageSubscriptionsListsConfig,
    getDefaultFieldsetsListsConfig,
    getVortalCompaniesListsConfig,
    getVortalContactsListsConfig,
    getVortalLogsListsConfig,
    getVortalNotificationEmailsListsConfig,
    getVortalProjectsListsConfig,
    getMyranUserVersionsListsConfig,
    getMyranSubscriptionVersionsListsConfig,
    getMyranClientVersionsListsConfig,
    getMyranUserProfilesListsConfig,
    getMyranSuspendedUsersListsConfig,
    getUsageStatsUsersListsConfig,
    getUsageStatsUserWidgetsListsConfig,
    getUsageStatsClientsListsConfig,
    getCustomFieldsetListConfig,
    getCustomFieldMetricsListsConfig,
    getCustomFieldSelectionMetricsListsConfig,
    getPMCompanyListsConfig,
    getPMClientListsConfig,
    getPMClientVersionListsConfig,
    getPMActivityListsConfig,
    getPMContactListsConfig,
    getPMProjectsListsConfig,
    getPMEmployeeListsConfig,
    getPMDocumentListsConfig,
    getPMCustomFieldListsConfig,
    getPMCustomFieldSelectionListsConfig,
    getPMCustomFieldValueListsConfig,
    getPMCustomFieldEmployeeListsConfig,
    getErrorMessagesListsConfig,
    getSubscriptionsListsConfig,
    getListUsersListsConfig,
    getUserProfilesListsConfig,
    getLicenseListsConfig,
    getDefaultDashboardWidgetsListConfig,
    getDefaultExportFieldsetsListsConfig,
  ];

  return configsFuncs.reduce(
    (acum, configFunc) => ({
      ...acum,
      ...configFunc(),
    }),
    {},
  );
};

export {
  // configs
  getEntityListConfigs,
  // entities
  Project,
  UserProject,
  Company,
  UserCompany,
  Tender,
  UserTender,
  Contact,
  UserContact,
  Activity,
  Client,
  Note,
  File,
  User,
  ReleaseNote,
  ApiClient,
  Webhook,
  UserWebhook,
  WebhooksQueue,
  EmailLog,
  SavedSearchNotification,
  UserTransferTask,
  EmailLogEvent,
  Component,
  Module,
  DeadMessage,
  DeadMessageSubscription,
  DefaultFieldset,
  VortalCompany,
  VortalContact,
  VortalProject,
  VortalLog,
  VortalNotificationEmail,
  MyranUserProfile,
  MyranSuspendedUser,
  MyranClientVersion,
  MyranUserVersion,
  MyranSubscriptionVersion,
  UsageStatsUser,
  UsageStatsClient,
  UsageStatsUserWidgets,
  CustomFieldset,
  CustomFieldMetric,
  CustomFieldSelectionMetric,
  PMClient,
  PMClientVersion,
  PMCompany,
  PMContact,
  PMProject,
  PMDocument,
  PMEmployee,
  PMActivity,
  PMCustomField,
  PMCustomFieldSelection,
  PMCustomFieldValue,
  PMCustomFieldEmployee,
  Subscription,
  ClientUser,
  UserProfile,
  License,
  DefaultDashboardWidgets,
  CountryModules,
  DefaultExportFieldset,
  UsageStatsClientWidget,
  // lists
  ProjectsList,
  UserProjectsList,
  TendersList,
  UserTendersList,
  CompaniesList,
  UserCompaniesList,
  ContactsList,
  UserContactsList,
  ActivitiesList,
  NotesList,
  FilesList,
  ClientsList,
  UsersList,
  ReleaseNotesList,
  ApiClientsList,
  WebhooksList,
  UserWebhooksList,
  WebhooksQueueList,
  EmailLogsList,
  SavedSearchNotificationsList,
  UserTransferTasksList,
  EmailLogEventsList,
  ComponentsList,
  ModulesList,
  DeadMessageSubscriptionsList,
  DeadMessagesList,
  DefaultFieldsetsList,
  VortalCompaniesList,
  VortalContactsList,
  VortalNotificationEmailsList,
  VortalLogsList,
  VortalProjectsList,
  MyranSubscriptionVersionsList,
  MyranUserVersionsList,
  MyranUserProfilesList,
  MyranSuspendedUsersList,
  MyranClientVersionsList,
  UsageStatsClientsList,
  UsageStatsUsersList,
  UsageStatsUserWidgetsList,
  CustomFieldsetList,
  CustomFieldMetricsList,
  CustomFieldSelectionMetricsList,
  PMClientsList,
  PMClientVersionsList,
  PMCompaniesList,
  PMContactsList,
  PMDocumentsList,
  PMActivitiesList,
  PMEmployeesList,
  PMProjectsList,
  PMCustomFieldsList,
  PMCustomFieldSelectionsList,
  PMCustomFieldValuesList,
  PMCustomFieldEmployeesList,
  ErrorMessagesList,
  ErrorMessage,
  SubscriptionsList,
  ClientUsersList,
  UserProfilesList,
  LicensesList,
  DefaultDashboardWidgetsList,
  CountryModulesList,
  DefaultExportFieldsetsList,
  UsageStatsClientWidgetsList,
};
