/*
  UsageStatsClientWidget class
 */

import Vue from 'vue';
import BaseEntity from '@/shared/classes/entity/BaseEntity';
import { ENTITY_TYPES } from '@/config/enums';
import { getUSWidgetClients } from '@/api/repositories/usageStatisticsRepository';

export class UsageStatsClientWidget extends BaseEntity {
  static entityKey = 'usage_stats_client_widget';
  static datasetKey = 'usageStatsClientsWidget';
  static idKey = 'client_id';
  static entityTypeId = ENTITY_TYPES.USAGE_STATS_CLIENT_WIDGET;
  static navigationIdKey = 'client_id';
  static routes = {
    view: 'UsageStatisticsClientWidgets',
    list: 'UsageStatisticsClientsWidgets',
  };

  // api
  static loadAllItemsFunc = getUSWidgetClients;

  static getEntityPluralTypeText() {
    return Vue.prototype.$vDict('clients.plural_clients_text.text');
  }

  constructor(...args) {
    super(() => ({}), ...args);

    this.selfClass = UsageStatsClientWidget;
    this.id = this.getValue(this.selfClass.idKey);
    this.navigationId = this.getValue(this.selfClass.navigationIdKey);
  }

  getName() {
    return this.getValue('client_name');
  }

  getFieldValue(fieldKey) {
    const fieldValue = this.getValue(fieldKey);
    let res;

    switch (fieldKey) {
      case 'stat_wg_market_trends':
      case 'stat_wg_news':
      case 'stat_wg_find_me':
      case 'stat_wg_project_explorer':
      case 'stat_wg_save_search':
      case 'stat_wg_custom_email_notif':
        const view = fieldValue?.view || 0;
        const click = fieldValue?.click || 0;
        res = `${view} / ${click}`;
        break;
      default:
        res = super.getFieldValue(fieldKey);
    }

    return res;
  }
}
