<template>
  <div class="fieldset-field">
    <component
      :is="resultComponent"
      v-if="resultComponent"
      :entity="entity"
    />
  </div>
</template>

<script>
export default {
  name: 'fieldset-field',
  props: {
    entity: {
      type: Object,
      required: true,
    },
  },
  computed: {
    resultComponent() {
      const listTypeId = this.entity.getValue('list_type_id');
      const listTypes = this.entity.constructor.getListTypes();
      const listTypeSettings = listTypes.find((listType) => listType.id === listTypeId);

      return listTypeSettings.useResultComponent;
    },
  },
};
</script>

<style lang="scss" scoped></style>
