/*
  ComponentsList class
 */

import Vue from 'vue';
import EntityList from '@/shared/classes/list/EntityList';
import ComponentsTableView from '@/entities/component/views/ComponentsTableView';
import { getComponentsListsConfig } from '@/entities/component/config';
import { requiredFields } from '@/entities/component/fields';
import { Component } from '@/entities';
import { SEARCH_FILTER_TYPES, SEARCH_FIELD_TYPE } from '@/config/enums';

export class ComponentsList extends EntityList {
  static entityClass = Component;

  constructor(listTypeId) {
    super(listTypeId, getComponentsListsConfig);

    this.tableView = ComponentsTableView;

    this.selfClass = ComponentsList;
    this.getRequiredFields = requiredFields;
  }

  getListSearchFields() {
    return [
      {
        fieldKey: 'name',
        fieldType: SEARCH_FIELD_TYPE.CUSTOM,
        fieldAttrs: {
          dataCy: 'search-keywords',
        },
        filterType: SEARCH_FILTER_TYPES.KEYWORDS,
        filterAttrs: {
          placeholder: Vue.prototype.$vDict('system_components.filter_keywords_input_placeholder.text'),
          autocomplete: true,
          autocompleteSettings: {
            autocompleteCallback: async (searchText, query) => {
              let response;

              try {
                response = await Component.loadAllItemsFunc({
                  ...query,
                  jsFilter: {
                    name: searchText,
                  },
                });
              } catch (error) {
                console.log(error);
              }

              return {
                items: (response?.data || []).map((component) => ({
                  id: component.id,
                  name: component.name,
                })),
                total: response?.meta?.total,
              };
            },
          },
        },
      },
    ];
  }
}
