import Vue from 'vue';

export const requiredFields = () => [
  {
    key: 'user_name',
    label: Vue.prototype.$vDict('usage_stats.field_user_name.text'),
    sortable: true,
  },
  {
    key: 'stat_project',
    label: Vue.prototype.$vDict('usage_stats.stat_project_view.text'),
    sortable: true,
    sortKeys: ['stat_project.view'],
  },
  {
    key: 'stat_tender',
    label: Vue.prototype.$vDict('usage_stats.stat_tender_view.text'),
    sortable: true,
    sortKeys: ['stat_tender.view'],
  },
  {
    key: 'stat_company',
    label: Vue.prototype.$vDict('usage_stats.stat_company_view.text'),
    sortable: true,
    sortKeys: ['stat_company.view'],
  },
  {
    key: 'stat_contact',
    label: Vue.prototype.$vDict('usage_stats.stat_contact_view.text'),
    sortable: true,
    sortKeys: ['stat_contact.view'],
  },
  {
    key: 'stat_auth',
    label: Vue.prototype.$vDict('usage_stats.stat_auth_month.text'),
    sortable: true,
  },
  {
    key: 'last_login',
    label: Vue.prototype.$vDict('usage_stats.last_login.text'),
    sortable: true,
  },
];
