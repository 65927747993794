import { LIST_TYPES } from '@/config/enums';

export const getUsageStatsUserWidgetsListsConfig = () => ({
  [LIST_TYPES.USAGE_STATISTICS_USERS_WIDGETS]: {
    // isMain: true,
    // isSavePosition: true,
  },
  [LIST_TYPES.USAGE_STATISTICS_CLIENT_USERS_WIDGETS]: {
    // isMain: true,
    // isSavePosition: true,
  },
});
