/*
  UsageStatsClientWidgetsList class
 */

import EntityList from '@/shared/classes/list/EntityList';
import UsageStatsClientWidgetsTableView from '@/entities/usage-stats-client-widgets/views/UsageStatsClientWidgetsTableView';
import { getUsageStatsClientsListsConfig } from '@/entities/usage-stats-client-widgets/config';
import { requiredFields } from '@/entities/usage-stats-client-widgets/fields';
import { UsageStatsClientWidget } from '@/entities';

export class UsageStatsClientWidgetsList extends EntityList {
  static entityClass = UsageStatsClientWidget;

  constructor(listTypeId) {
    super(listTypeId, getUsageStatsClientsListsConfig);

    this.tableView = UsageStatsClientWidgetsTableView;

    this.selfClass = UsageStatsClientWidgetsList;
    this.getRequiredFields = requiredFields;
  }
}
