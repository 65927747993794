<template>
  <base-table-view
    class="usage-stats-users-table-view"
    selectable
    row-number
    :items="items"
    :fields="fields"
    :sort="sort"
    :entity-list="entityList"
    :fixed-required-columns="fixedRequiredColumns"
    @change-sort="changeSort"
  >
    <template v-slot:cell(user_name)="data">
      <field-user
        :user="data.item"
        show-email
      />
    </template>
  </base-table-view>
</template>

<script>
import BaseTableView from '@/components/entity-list/views/table/BaseTableView';
import tableViewMixin from '@/components/entity-list/views/table/tableViewMixin';
import FieldUser from '@/components/entity-fields/FieldUser.vue';

export default {
  name: 'usage-stats-users-table-view',
  components: {
    FieldUser,
    BaseTableView,
  },
  mixins: [tableViewMixin],
  computed: {},
  methods: {
    getLinkToUser(item) {
      const { clientId } = this.entityList;
      const linkTo = {
        name: 'UsageStatisticsUser',
        params: {
          id: item.getValue('user_id'),
        },
      };

      if (clientId) {
        Object.assign(linkTo, {
          query: {
            client_id: clientId,
          },
        });
      }

      return linkTo;
    },
  },
};
</script>

<style lang="scss" scoped>
.usage-stats-users-table-view {
  &::v-deep .dt-table__td {
    vertical-align: middle;
  }

  .field-title-email {
    font-size: 12px;
    line-height: 14px;
  }
}
</style>
