/*
  UsageStatsUsersList class
 */

import EntityList from '@/shared/classes/list/EntityList';
import UsageStatsUsersTableView from '@/entities/usage-stats-user-widgets/views/UsageStatsUsersTableView';
import { getUsageStatsUserWidgetsListsConfig } from '@/entities/usage-stats-user-widgets/config';
import { requiredFields } from '@/entities/usage-stats-user-widgets/fields';
import { UsageStatsUserWidgets } from '@/entities';


export class UsageStatsUserWidgetsList extends EntityList {
  static entityClass = UsageStatsUserWidgets;

  constructor(listTypeId) {
    super(listTypeId, getUsageStatsUserWidgetsListsConfig);

    this.tableView = UsageStatsUsersTableView;

    this.selfClass = UsageStatsUserWidgetsList;
    this.getRequiredFields = requiredFields;
  }
}
