import _unionBy from 'lodash/unionBy';
import { useEntityListTypes } from '@/entities/default-fieldset/list-types/entity-list-settings';
import { useCustomNotificationListTypes } from '@/entities/default-fieldset/list-types/custom-notifications-settings';

export function useListTypes() {
  const entityListTypes = useEntityListTypes();
  const customNotificationListTypes = useCustomNotificationListTypes();

  return _unionBy(entityListTypes, customNotificationListTypes, 'id');
}
