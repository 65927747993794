/*
  DefaultFieldset class
 */

import Vue from 'vue';
import BaseEntity from '@/shared/classes/entity/BaseEntity';
import { ENTITY_TYPES } from '@/config/enums';
import {
  deleteDefaultFieldset,
  getDefaultFieldsets,
  getDefaultFieldset,
  putDefaultFieldset,
  postDefaultFieldset,
} from '@/api/repositories/defaultFieldsetsRepository';
import { useListTypes } from '@/entities/default-fieldset/list-types';
import { isJSON } from '@/shared/utils';

export class DefaultFieldset extends BaseEntity {
  static entityKey = 'default_fieldset';
  static datasetKey = 'defaultFieldsets';
  static entityTypeId = ENTITY_TYPES.DEFAULT_FIELDSET;
  static routes = {
    edit: 'SystemEditorDefaultFieldset',
    create: 'SystemEditorDefaultFieldsetNew',
    list: 'SystemDefaultFieldsets',
  };

  // api
  static loadAllItemsFunc = getDefaultFieldsets;
  static loadItemFunc = getDefaultFieldset;
  static putItemFunc = putDefaultFieldset;
  static postItemFunc = postDefaultFieldset;
  static deleteItemFunc = deleteDefaultFieldset;

  static getEntityPluralTypeText() {
    return Vue.prototype.$vDict('default_fieldsets.plural_default_fieldsets_text.text');
  }

  constructor(...args) {
    super(() => ({}), ...args);

    this.selfClass = DefaultFieldset;
  }

  static getAllActions() {
    return [
      {
        key: 'edit',
        name: 'Edit',
        icon: 'edit',
      },
      {
        key: 'delete',
        name: 'Delete',
        icon: 'trash',
        linkClass: 'action-link--warning',
      },
    ];
  }

  static getListTypes() {
    return useListTypes();
  }

  getFieldValue(fieldKey) {
    const fieldValue = this.getValue(fieldKey);
    let res;
    let value;

    switch (fieldKey) {
      case 'country_id':
        res = Vue.prototype.$lFind('global.countries', { id: fieldValue, prop: 'name' });
        break;
      case 'list_type_id':
        value = this.getItemFromEnum('LIST_TYPES', fieldValue);
        res = value?.name || '';
        break;
      default:
        res = super.getFieldValue(fieldKey);
    }

    return res;
  }

  getFieldsetValue() {
    const items = this.getValue('fieldset', []);
    return isJSON(items[0]) ? JSON.parse(items[0]) : items;
  }
}
