import Vue from 'vue';

export const requiredFields = () => [
  {
    key: 'user_name',
    label: Vue.prototype.$vDict('usage_stats.field_user_name.text'),
    sortable: true,
  },
  {
    key: 'stat_wg_market_trends',
    label: Vue.prototype.$vDict('dashboard.widget_market_trends_title.text'),
    sortable: true,
    sortKeys: ['stat_wg_market_trends.view'],
    // multiSort: [
    //   {
    //     key: 'stat_wg_market_trends_views',
    //     label: Vue.prototype.$vDict('usage_stats_widgets.sort_by_views_label.text'),
    //   },
    //   {
    //     key: 'stat_wg_market_trends_clicks',
    //     label: Vue.prototype.$vDict('usage_stats_widgets.sort_by_clicks_label.text'),
    //   },
    // ],
  },
  {
    key: 'stat_wg_news',
    label: Vue.prototype.$vDict('dashboard.widget_news_title.text'),
    sortable: true,
    sortKeys: ['stat_wg_news.view'],
    // multiSort: [
    //   {
    //     key: 'stat_wg_news_views',
    //     label: Vue.prototype.$vDict('usage_stats_widgets.sort_by_views_label.text'),
    //   },
    //   {
    //     key: 'stat_wg_news_clicks',
    //     label: Vue.prototype.$vDict('usage_stats_widgets.sort_by_clicks_label.text'),
    //   },
    // ],
  },
  {
    key: 'stat_wg_find_me',
    label: Vue.prototype.$vDict('dashboard.widget_find_me_title.text'),
    sortable: true,
    sortKeys: ['stat_wg_find_me.view'],
    // multiSort: [
    //   {
    //     key: 'stat_wg_find_me_views',
    //     label: Vue.prototype.$vDict('usage_stats_widgets.sort_by_views_label.text'),
    //   },
    //   {
    //     key: 'stat_wg_find_me_clicks',
    //     label: Vue.prototype.$vDict('usage_stats_widgets.sort_by_clicks_label.text'),
    //   },
    // ],
  },
  {
    key: 'stat_wg_project_explorer',
    label: Vue.prototype.$vDict('dashboard.widget_project_explorer_title.text'),
    sortable: true,
    sortKeys: ['stat_wg_project_explorer.view'],
    // multiSort: [
    //   {
    //     key: 'stat_wg_project_explorer_views',
    //     label: Vue.prototype.$vDict('usage_stats_widgets.sort_by_views_label.text'),
    //   },
    //   {
    //     key: 'stat_wg_project_explorer_clicks',
    //     label: Vue.prototype.$vDict('usage_stats_widgets.sort_by_clicks_label.text'),
    //   },
    // ],
  },
  // {
  //   key: 'stat_wg_save_search',
  //   label: Vue.prototype.$vDict('dashboard.widget_save_search_title.text'),
  //   sortable: true,
  //   sortKeys: ['stat_wg_save_search.view'],
  //   multiSort: [
  //     {
  //       key: 'stat_wg_save_search_views',
  //       label: Vue.prototype.$vDict('usage_stats_widgets.sort_by_views_label.text'),
  //     },
  //     {
  //       key: 'stat_wg_save_search_clicks',
  //       label: Vue.prototype.$vDict('usage_stats_widgets.sort_by_clicks_label.text'),
  //     },
  //   ],
  // },
  {
    key: 'stat_wg_custom_email_notif',
    label: Vue.prototype.$vDict('dashboard.widget_custom_email_notif_title.text'),
    sortable: true,
    sortKeys: ['stat_wg_custom_email_notif.view'],
    // multiSort: [
    //   {
    //     key: 'stat_wg_custom_email_notif_views',
    //     label: Vue.prototype.$vDict('usage_stats_widgets.sort_by_views_label.text'),
    //   },
    //   {
    //     key: 'stat_wg_custom_email_notif_clicks',
    //     label: Vue.prototype.$vDict('usage_stats_widgets.sort_by_clicks_label.text'),
    //   },
    // ],
  },
];
