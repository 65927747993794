/*
  UsageStatsClient class
 */

import Vue from 'vue';
import BaseEntity from '@/shared/classes/entity/BaseEntity';
import { ENTITY_TYPES } from '@/config/enums';
import { getUSWidgetUsers } from '@/api/repositories/usageStatisticsRepository';

export class UsageStatsUserWidgets extends BaseEntity {
  static entityKey = 'usage_stats_user_widget';
  static datasetKey = 'usageStatsUserWidgets';
  static idKey = 'user_id';
  static entityTypeId = ENTITY_TYPES.USAGE_STATS_USER_WIDGET;
  static navigationIdKey = 'user_id';
  static routes = {
    view: 'UsageStatisticsUserWidgets',
    list: 'UsageStatisticsUsersWidgets',
  };

  // api
  static loadAllItemsFunc = getUSWidgetUsers;

  static getEntityPluralTypeText() {
    return Vue.prototype.$vDict('users.plural_users_text.text');
  }

  constructor(...args) {
    super(() => ({}), ...args);

    this.selfClass = UsageStatsUserWidgets;
    this.id = this.getValue(this.selfClass.idKey);
    this.navigationId = this.getValue(this.selfClass.navigationIdKey);
  }

  getName() {
    return this.getValue('user_name');
  }

  getEmail() {
    return this.getValue('user_email');
  }

  isDeleted() {
    return this.getValue('user_deleted');
  }

  getLinkTo() {
    const linkTo = super.getLinkTo();
    const clientId = this.listInstance?.clientId;

    if (clientId) {
      Object.assign(linkTo, {
        query: {
          ...(linkTo.query || {}),
          client_id: clientId,
        },
      });
    }

    return linkTo;
  }

  getFieldValue(fieldKey) {
    const fieldValue = this.getValue(fieldKey);
    let res;

    switch (fieldKey) {
      case 'stat_wg_market_trends':
      case 'stat_wg_news':
      case 'stat_wg_find_me':
      case 'stat_wg_project_explorer':
      case 'stat_wg_save_search':
      case 'stat_wg_custom_email_notif':
        const view = fieldValue?.view || 0;
        const click = fieldValue?.click || 0;
        res = `${view} / ${click}`;
        break;
      default:
        res = super.getFieldValue(fieldKey);
    }

    return res;
  }
}
