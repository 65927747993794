import Vue from 'vue';

export const requiredFields = () => [
  {
    key: 'client_id',
    label: Vue.prototype.$vDict('usage_stats.field_client_id.text'),
    class: 'column-id',
    sortable: true,
  },
  {
    key: 'client_name',
    label: Vue.prototype.$vDict('usage_stats.field_client_name.text'),
    sortable: true,
  },
  {
    key: 'stat_wg_market_trends',
    label: Vue.prototype.$vDict('dashboard.widget_market_trends_title.text'),
    sortable: true,
    sortKeys: ['stat_wg_market_trends.view'],
  },
  {
    key: 'stat_wg_news',
    label: Vue.prototype.$vDict('dashboard.widget_news_title.text'),
    sortable: true,
    sortKeys: ['stat_wg_news.view'],
  },
  {
    key: 'stat_wg_find_me',
    label: Vue.prototype.$vDict('dashboard.widget_find_me_title.text'),
    sortable: true,
    sortKeys: ['stat_wg_find_me.view'],
  },
  {
    key: 'stat_wg_project_explorer',
    label: Vue.prototype.$vDict('dashboard.widget_project_explorer_title.text'),
    sortable: true,
    sortKeys: ['stat_wg_project_explorer.view'],
  },
  // {
  //   key: 'stat_wg_save_search',
  //   label: Vue.prototype.$vDict('dashboard.widget_save_search_title.text'),
  //   sortable: true,
  //   sortKeys: ['stat_wg_save_search.view'],
  // },
  {
    key: 'stat_wg_custom_email_notif',
    label: Vue.prototype.$vDict('dashboard.widget_custom_email_notif_title.text'),
    sortable: true,
    sortKeys: ['stat_wg_custom_email_notif.view'],
  },
];
