<template>
  <base-table-view
    class="usage-stats-client-widgets-table-view"
    selectable
    :items="items"
    :fields="fields"
    :sort="sort"
    :entity-list="entityList"
    :fixed-required-columns="fixedRequiredColumns"
    @change-sort="changeSort"
  >
    <template v-slot:cell(client_id)="data">
      <span class="field-client-id">{{ data.item.id }}</span>
    </template>

    <template v-slot:cell(client_name)="data">
      <field-title :entity="data.item" />
    </template>
  </base-table-view>
</template>

<script>
import BaseTableView from '@/components/entity-list/views/table/BaseTableView';
import tableViewMixin from '@/components/entity-list/views/table/tableViewMixin';
import FieldTitle from '@/components/entity-fields/FieldTitle.vue';

export default {
  name: 'usage-stats-client-widgets-table-view',
  components: {
    BaseTableView,
    FieldTitle,
  },
  mixins: [tableViewMixin],
  computed: {},
};
</script>

<style lang="scss" scoped>
.field-client-id {
  color: var(--text-muted);
}
</style>
