/*
  UsageStatsUser class
 */

import BaseEntity from '@/shared/classes/entity/BaseEntity';
import { ENTITY_TYPES } from '@/config/enums';
import { getUSUsers } from '@/api/repositories/usageStatisticsRepository';
import { getDateTextOffsetToday } from '@/shared/utils';
import Vue from 'vue';

export class UsageStatsUser extends BaseEntity {
  static entityKey = 'usage_stats_user';
  static datasetKey = 'usageStatsUsers';
  static idKey = 'user_id';
  static entityTypeId = ENTITY_TYPES.USAGE_STATS_USER;
  static navigationIdKey = 'user_id';
  static routes = {
    view: 'UsageStatisticsUser',
    list: 'UsageStatisticsUsers',
  };

  // api
  static loadAllItemsFunc = getUSUsers;

  static getEntityPluralTypeText() {
    return Vue.prototype.$vDict('users.plural_users_text.text');
  }

  constructor(...args) {
    super(() => ({}), ...args);

    this.selfClass = UsageStatsUser;
    this.id = this.getValue(this.selfClass.idKey);
    this.navigationId = this.getValue(this.selfClass.navigationIdKey);
  }

  getName() {
    return this.getValue('user_name');
  }

  getEmail() {
    return this.getValue('user_email');
  }

  isDeleted() {
    return this.getValue('user_deleted');
  }

  getLinkTo() {
    const linkTo = super.getLinkTo();
    const clientId = this.listInstance?.clientId;

    if (clientId) {
      Object.assign(linkTo, {
        query: {
          ...(linkTo.query || {}),
          client_id: clientId,
        },
      });
    }

    return linkTo;
  }

  getFieldValue(fieldKey) {
    const fieldValue = this.getValue(fieldKey);
    let res;

    switch (fieldKey) {
      case 'avatar':
        res = {
          name: this.getValue('user_name'),
          email: this.getValue('user_email'),
        };
        break;
      case 'last_login':
        res = fieldValue ? getDateTextOffsetToday(fieldValue) : '';
        break;
      case 'stat_project':
      case 'stat_tender':
      case 'stat_company':
      case 'stat_contact':
        res = fieldValue?.view || 0;
        break;
      default:
        res = super.getFieldValue(fieldKey);
    }

    return res;
  }
}
