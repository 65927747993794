import Vue from 'vue';
import { getEntityListConfigs } from '@/entities';

function getListTypes() {
  const listTypes = Vue.prototype.$getEnumsList('LIST_TYPES');
  const listsConfigs = getEntityListConfigs();

  return listTypes.filter((listType) => listsConfigs[listType.id]?.customDefaultFieldset);
}

export function useEntityListTypes() {
  const listTypes = getListTypes();

  return listTypes.map((listType) => {
    const listTypeId = listType.id;

    return {
      id: listTypeId,
      name: listType.name,
      useFormComponent: () => import('./DefaultFieldsetForm.vue'),
      useResultComponent: () => import('./DefaultFieldsetResult.vue'),
    };
  });
}
