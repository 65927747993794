import Vue from 'vue';
import { LIST_TYPES } from '@/config/enums';

export function useCustomNotificationListTypes() {
  const allListTypes = Vue.prototype.$getEnumsList('LIST_TYPES');
  const listTypes = allListTypes.filter((listType) =>
    [LIST_TYPES.USER_PROFILE_CUSTOM_NOTIFICATIONS].includes(listType.id),
  );

  return listTypes.map((listType) => {
    return {
      id: listType.id,
      name: listType.name,
      useFormComponent: () => import('./DefaultFieldsetForm.vue'),
      useResultComponent: () => import('./DefaultFieldsetResult.vue'),
    };
  });
}
