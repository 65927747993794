<template>
  <base-table-view
    class="files-table-view"
    selectable
    :items="items"
    :fields="fields"
    :sort="sort"
    :entity-list="entityList"
    :fixed-required-columns="fixedRequiredColumns"
    @change-sort="changeSort"
  >
    <template v-slot:cell(fieldset)="data">
      <fieldset-field :entity="data.item" />
    </template>
  </base-table-view>
</template>

<script>
import BaseTableView from '@/components/entity-list/views/table/BaseTableView';
import tableViewMixin from '@/components/entity-list/views/table/tableViewMixin';
import FieldsetField from '@/entities/default-fieldset/views/FieldsetField.vue';

export default {
  name: 'files-table-view',
  components: {
    BaseTableView,
    FieldsetField,
  },
  mixins: [tableViewMixin],
};
</script>

<style lang="scss" scoped></style>
